.auth-form{
  width: 400px;
  margin: auto;
}
.modal-form{
  padding: 10px;
}
.modal-actions{
  padding-top: 10px;
}
.padded{
  margin: 10px !important;
}